/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WritingInstructionResponseItemDto } from '@ink-ai/insight-service-sdk';

export type ComposeInstructionState = {
  currentTemplate: WritingInstructionResponseItemDto | null;
  userInstructionText: string;
  userInstructionInputObj: { [key: string]: string };
  currentCharacterCount: number;
};

const initialState: ComposeInstructionState = {
  currentTemplate: null,
  userInstructionText: '',
  userInstructionInputObj: {},
  currentCharacterCount: 0,
};

const calculateTemplateTextLength = (state): number => {
  if (state.currentTemplate) {
    const templateText = state.userInstructionText.replace(
      /\{([^{}]+)\}/g,
      (match, key) => {
        return key in state.userInstructionInputObj
          ? state?.userInstructionInputObj?.[key]
          : match ?? '';
      },
    );
    return templateText.length;
  } else {
    return state.userInstructionText.length;
  }
};

export const composeInstruction: ReturnType<typeof createSlice> = createSlice({
  name: 'composeInstruction',
  initialState,
  reducers: {
    setFullState(_state, { payload }: PayloadAction<ComposeInstructionState>) {
      console.log('setComposeInstructionState', payload);
      return payload;
    },
    resetInstruction: (state) => {
      state.currentTemplate = null;
      state.userInstructionText = '';
      state.userInstructionInputObj = {};
      state.currentCharacterCount = 0;
    },
    setCurrentTemplate: (state, action) => {
      state.currentTemplate = action.payload;
      state.userInstructionText = action.payload?.userInstruction ?? '';
      state.userInstructionInputObj = {};
      state.currentCharacterCount = calculateTemplateTextLength(state);
    },
    changeUserInstructionText: (state, action) => {
      if (action.payload.length > 1000) {
        state.userInstructionText = action.payload.substring(0, 1000);
      } else {
        state.userInstructionText = action.payload;
      }
      state.currentCharacterCount = calculateTemplateTextLength(state);
    },
    changeUserInstructionTemplateInput: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: string;
        value: string;
      }>,
    ) => {
      const { key, value } = payload;
      // can not input when character count is over 1000
      const charLength = calculateTemplateTextLength(state);
      if (charLength < 1000) {
        state.userInstructionInputObj[key] = value;
      }
      state.currentCharacterCount = calculateTemplateTextLength(state);
    },
  },
});

export const composeInstructionActions = composeInstruction.actions;
export default composeInstruction.reducer;
