/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import { documentMetrics, sentenceBreak } from '@ink-ai/dbrr-toolkit';

export const asyncDocumentMetrics = (doc: string) =>
  new Promise<ReturnType<typeof documentMetrics>>((resolve) => {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(
        new URL('../../service-workers/scoreWorker.ts', import.meta.url),
        {
          type: 'module',
        },
      );
      worker.addEventListener('message', (event: MessageEvent) => {
        console.log('Worker said: ', event.data.result);
        resolve(event.data.result);
        worker.terminate();
      });

      worker.postMessage({ doc });
    } else {
      resolve(documentMetrics(doc));
    }
  });

export const asyncSentenceBreak = (doc: string) =>
  new Promise<string[]>((resolve) => {
    if (typeof Worker !== 'undefined') {
      const worker = new Worker(
        new URL(
          '../../service-workers/sentenceBreakWorker.ts',
          import.meta.url,
        ),
        {
          type: 'module',
        },
      );
      worker.addEventListener('message', (event: MessageEvent) => {
        console.log('Worker said: ', event.data.result);
        resolve(event.data.result);
        worker.terminate();
      });

      worker.postMessage({ doc });
    } else {
      resolve(sentenceBreak(doc));
    }
  });
