/*
Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.

Licensed under the Apache License, Version 2.0 (the "License").
You may not use this file except in compliance with the License.
You may obtain a copy of the License at

    http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/
import React, { useEffect, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '@ink-ai/portal/reducers';
import CitationCard from './CitationCard';

export interface CitationPageProps {
  className?: string;
  isPreview?: boolean;
}

export const CitationPage = ({ isPreview, className }: CitationPageProps) => {
  const article = useSelector((state: RootState) => state.article);
  const { citations, previewCitations, currentCitationId } = article;
  useEffect(() => {
    const element = document.getElementById(`plugin-card-${currentCitationId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentCitationId]);

  const citationList = useMemo(() => {
    if (isPreview) {
      if (article.previewCitationFilterText) {
        const filterText = article.previewCitationFilterText.toLowerCase();
        return previewCitations.filter(
          (citation) =>
            citation.title.toLowerCase().includes(filterText) ||
            citation.text.toLowerCase().includes(filterText),
        );
      }
      return previewCitations;
    }
    return citations;
  }, [
    previewCitations,
    citations,
    isPreview,
    article.previewCitationFilterText,
  ]);

  return (
    <div
      className={`${className}`}
      style={{ height: '100%', overflow: 'auto' }}
    >
      <Box sx={{ padding: isPreview ? 0 : 2 }}>
        {citationList.length > 0 ? (
          citationList.map((citation) => (
            <CitationCard
              isPreview={isPreview}
              key={citation.id}
              citation={citation}
            />
          ))
        ) : (
          <Typography textAlign="center" variant="body1" color="textSecondary">
            No citations available.
          </Typography>
        )}
      </Box>
    </div>
  );
};
